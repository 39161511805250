import React from 'react';
import { useParams } from 'react-router-dom';

const amenities = [
  [
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/1.png",
    title: "Secure Gated Community",
    description: "Enjoy peace of mind in our gated community.",
  },
  {
    // 
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/6.png",
    title: "Exclusive Clubhouse",
    description: "Relax and rejuvenate in our premium clubhouse.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/9.png",
    title: "Restaurant",
    description: "Savor delightful meals in our on-site restaurant.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/12.png",
    title: "Room Service",
    description: "Room service for your convenience.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/5.png",
    title: "Complimentary Parking",
    description: "Free and secure parking for all guests.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/3.png",
    title: "Breakfast",
    description: "Start your day with a delicious breakfast.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/7.png",
    title: "Landscaped Gardens",
    description: "Relax amidst our beautifully landscaped gardens.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/2.png",
    title: "Wi-Fi",
    description: "Stay connected with complimentary high-speed Wi-Fi.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/10.png",
    title: "24*7 Security Surveillance",
    description: "Advanced surveillance for your safety.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/11.png",
    title: "Panoramic Himalayan Views",
    description: "Witness breathtaking views of the Himalayas.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/8.png",
    title: "Spacious Family Rooms",
    description: "Comfortable rooms designed for families.",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/13.png",
    title: "Couple Friendly Rooms",
    description: "Cozy rooms for a romantic stay.",
  },
],
[
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/bedroom/14.png",
    title: "DOUBLE BEDS",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/bedroom/15.png",
    title: "WARDROBE",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/bedroom/16.png",
    title: "ROOM HEATER",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/bedroom/17.png",
    title: "TRAVEL DESK",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/bedroom/4.png",
    title: "HOUSE KEEPINGs",
  },
],
[
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/washroom/18.png",
    title: "TOILETRIES",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/washroom/19.png",
    title: "TOWELS",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/washroom/20.png",
    title: "MIRRORS",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/washroom/21.png",
    title: "SHOWER",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/washroom/22.png",
    title: "GEASER",
  },
],
[
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/kitchen/23.png",
    title: "CABINETS",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/kitchen/24.png",
    title: "INDUCTION, OVEN (ON REQUEST)",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/kitchen/25.png",
    title: "BASIC UTENSILS & CUTLERY",
  },
  {
    image: "https://d6pltnqzug32h.cloudfront.net/amenities/kitchen/26.png",
    title: "ELECTRIC KETTLE",
  },
]

];



const Amenities = () => {

  const url = useParams()
  // console.log(url.title)

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };
  return (
    <section className=" pb-[50px]">
      {!url.title && <div className='w-full h-[40vh] md:h-[380px] flex flex-col' style={{
                    backgroundImage: `url("https://d6pltnqzug32h.cloudfront.net/ame.avif")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <div className=' h-full w-full items-center justify-center flex flex-col'>
                        <div className='w-full h-full flex flex-col items-center justify-center'>
                            <div className='text-center text-2xl mt-[70px] instrument text-white
                        '>
                                <span className='md:text-6xl text-4xl lg:text-8xl pattaya'>Amenities</span> <br /> <span className='text-base md:text-xl lg:text-4xl'></span>
                            </div>
                        </div>

                    </div>
               </div>}
      <div className="container mx-auto px-4">
        {/* <h2 className="text-3xl mt-[40px] font-bold text-start mb-8 pattaya text-red-600">Our Amenities</h2> */}
        <div className="grid grid-cols-2 md:grid-cols-8 gap-8 mt-[30px]">
          {amenities[0].map((amenity, index) => (
            <div
              key={index}
              className="flex flex-col hover:scale-125 duration-500 cursor-pointer justify-center items-center text-center"
            >
              <img
                src={amenity.image}
                alt={amenity.title}
                className="mb-4 w-16 h-16 object-cover"
              />
              <h3 className="text-md font-semibold mb-2">{toTitleCase(amenity.title)}</h3>
            </div>
          ))}
        </div>
        <h2 className="text-3xl mt-[40px] font-bold text-start mb-8 pattaya text-red-600">Bedroom</h2>
        <div className="grid grid-cols-2 md:grid-cols-8 gap-8 mt-[30px]">
          {amenities[1].map((amenity, index) => (
            <div
              key={index}
              className="flex flex-col hover:scale-125 duration-500 cursor-pointer justify-center items-center text-center"
            >
              <img
                src={amenity.image}
                alt={amenity.title}
                className="mb-4 w-16 h-16 object-cover"
              />
              <h3 className="text-md font-semibold mb-2">{toTitleCase(amenity.title)}</h3>
            </div>
          ))}
        </div>
        <h2 className="text-3xl mt-[40px] font-bold text-start mb-8 pattaya text-red-600">Washroom</h2>
        <div className="grid grid-cols-2 md:grid-cols-8 gap-8 mt-[30px]">
          {amenities[2].map((amenity, index) => (
            <div
              key={index}
              className="flex flex-col hover:scale-125 duration-500 cursor-pointer justify-center items-center text-center"
            >
              <img
                src={amenity.image}
                alt={amenity.title}
                className="mb-4 w-16 h-16 object-cover"
              />
              <h3 className="text-md font-semibold mb-2">{toTitleCase(amenity.title)}</h3>
            </div>
          ))}
        </div>
        <h2 className="text-3xl mt-[40px] font-bold text-start mb-8 pattaya text-red-600">Kitchen</h2>
        <div className="grid grid-cols-2 md:grid-cols-8 gap-8 mt-[30px]">
          {amenities[3].map((amenity, index) => (
            <div
              key={index}
              className="flex flex-col hover:scale-125 duration-500 cursor-pointer justify-center items-center text-center"
            >
              <img
                src={amenity.image}
                alt={amenity.title}
                className="mb-4 w-16 h-16 object-cover"
              />
              <h3 className="text-md font-semibold mb-2">{toTitleCase(amenity.title)}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Amenities;

