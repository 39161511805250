import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Calendar from './Calendar';
import MobileBook from './MobileBook';
import { ChevronDown } from 'lucide-react';

function  HomeBook() {
    const navigate = useNavigate();
    const [bookingData, setBookingData] = useState({
        checkIn: new Date(),
        checkOut: new Date(new Date().setDate(new Date().getDate() + 1)),
        guests: 1
    });

    const [isCheckInCalendarOpen, setIsCheckInCalendarOpen] = useState(false);
    const [isCheckOutCalendarOpen, setIsCheckOutCalendarOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [hoverDate, setHoverDate] = useState(null);
    const [isGuestsDropdownOpen, setIsGuestsDropdownOpen] = useState(false);

    const checkInCalendarRef = useRef(null);
    const checkOutCalendarRef = useRef(null);
    const checkInTriggerRef = useRef(null);
    const checkOutTriggerRef = useRef(null);
    const guestsDropdownRef = useRef(null);

    // Custom date formatting
    const formatDate = (date) => {
        if (!date) return '';
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    // Generate calendar days for current month
    const generateCalendarDays = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const startingDay = firstDay.getDay();
        const days = [];

        for (let i = 0; i < startingDay; i++) {
            days.push(null);
        }

        for (let i = 1; i <= lastDay.getDate(); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const [calendarDays, setCalendarDays] = useState(generateCalendarDays(currentMonth));

    // Outside click handling
    useEffect(() => {
        const handleOutsideClick = (e) => {
            const checkInCalendarOpen =
                checkInCalendarRef.current &&
                !checkInCalendarRef.current.contains(e.target) &&
                checkInTriggerRef.current &&
                !checkInTriggerRef.current.contains(e.target);

            const checkOutCalendarOpen =
                checkOutCalendarRef.current &&
                !checkOutCalendarRef.current.contains(e.target) &&
                checkOutTriggerRef.current &&
                !checkOutTriggerRef.current.contains(e.target);

            const guestsDropdownOpen =
                guestsDropdownRef.current &&
                !guestsDropdownRef.current.contains(e.target);

            if (checkInCalendarOpen) {
                setIsCheckInCalendarOpen(false);
            }

            if (checkOutCalendarOpen) {
                setIsCheckOutCalendarOpen(false);
            }

            if (guestsDropdownOpen) {
                setIsGuestsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    // Month change handler
    const changeMonth = (increment) => {
        const newMonth = new Date(currentMonth);
        newMonth.setMonth(newMonth.getMonth() + increment);
        setCurrentMonth(newMonth);
        setCalendarDays(generateCalendarDays(newMonth));
    };

    // Check-in selection handler
    const handleCheckInSelect = (date) => {
        const defaultCheckOut = new Date(date);
        defaultCheckOut.setDate(date.getDate() + 1);

        setBookingData(prev => ({
            ...prev,
            checkIn: date,
            checkOut: defaultCheckOut
        }));

        setTimeout(() => {
            setIsCheckInCalendarOpen(false);
            setIsCheckOutCalendarOpen(true);
        }, 0);
    };

    // Check-out selection handler
    const handleCheckOutSelect = (date) => {
        if (!bookingData.checkIn) {
            alert("Please select a check-in date first.");
            return;
        }

        if (date <= bookingData.checkIn) {
            alert("Check-out date must be after check-in date.");
            return;
        }

        setBookingData(prev => ({
            ...prev,
            checkOut: date
        }));

        setTimeout(() => {
            setIsCheckOutCalendarOpen(false);
        }, 0);
    };

    // Booking handler
    const handleBooking = () => {
        const formdata = {
            ...bookingData,
            checkIn: bookingData.checkIn ? bookingData.checkIn : null,
            checkOut: bookingData.checkOut ? bookingData.checkOut : null
        };
        navigate('/availability', { state: formdata });
    };

    // Helper function to check if a date is within the selected range
    const isDateInRange = (date) => {
        if (!bookingData.checkIn) return false;
        const checkOutDate = bookingData.checkOut || hoverDate;
        if (!checkOutDate) return false;

        const start = bookingData.checkIn < checkOutDate ? bookingData.checkIn : checkOutDate;
        const end = bookingData.checkIn < checkOutDate ? checkOutDate : bookingData.checkIn;

        return date > start && date < end;
    };

    // Custom dropdown for guests
    const handleGuestsSelect = (guestCount) => {
        setBookingData(prev => ({
            ...prev,
            guests: guestCount
        }));
        setIsGuestsDropdownOpen(false);
    };

    return (
        <div className='h-full w-full z-30 items-center bg-black/10 justify-center flex flex-col relative'>
            <div className='w-full h-full flex flex-col items-center justify-center'>
                <div className='text-center text-2xl mt-[80px] instrument text-white'>
                    <span className='text-6xl md:text-8xl pattaya'>Above the Clouds</span> <br />
                    <span className='text-2xl md:text-4xl'>Experience Elegant Retreats</span>
                </div>

                {/* Desktop View() */}
                <div className='container hidden mx-auto w-full lg:flex flex-col items-center justify-center'>
                    <div className='py-3 px-3 w-full sm:w-[600px] md:w-[900px] flex gap-3 rounded-full mt-[40px] bg-white'>
                        <div
                            ref={checkInTriggerRef}
                            className='rounded-l-full py-2 border-r-[2px] cursor-pointer h-full w-full flex flex-col justify-evenly relative'
                            onClick={() => {
                                setIsCheckInCalendarOpen(true);
                                setIsCheckOutCalendarOpen(false);
                            }}
                        >
                            <p className='ml-[30px]'>Check In</p>
                            <p className='text-xs ml-[30px] text-gray-800'>
                                {formatDate(bookingData.checkIn)}
                            </p>
                            {isCheckInCalendarOpen && (
                                <div
                                    ref={checkInCalendarRef}
                                    className='absolute top-[-400%] left-0 z-50 w-[300px]'
                                >
                                    <Calendar
                                        currentMonth={currentMonth}
                                        changeMonth={changeMonth}
                                        calendarDays={calendarDays}
                                        bookingData={bookingData}
                                        hoverDate={hoverDate}
                                        isCheckIn={true}
                                        formatDate={formatDate}
                                        isDateInRange={isDateInRange}
                                        handleCheckInSelect={handleCheckInSelect}
                                        handleCheckOutSelect={handleCheckOutSelect}
                                        setHoverDate={setHoverDate}
                                    />
                                </div>
                            )}
                        </div>

                        <div
                            ref={checkOutTriggerRef}
                            className='h-full w-full py-2 border-r-[2px] cursor-pointer flex flex-col justify-evenly relative'
                            onClick={() => {
                                setIsCheckOutCalendarOpen(true);
                                setIsCheckInCalendarOpen(false);
                            }}
                        >
                            <p className='ml-[30px]'>Check Out</p>
                            <p className='text-xs ml-[30px] text-gray-800'>
                                {formatDate(bookingData.checkOut)}
                            </p>
                            {isCheckOutCalendarOpen && (
                                <div
                                    ref={checkOutCalendarRef}
                                    className='absolute top-[-400%] left-0  z-50 w-[300px]'
                                >
                                    <Calendar
                                        currentMonth={currentMonth}
                                        changeMonth={changeMonth}
                                        calendarDays={calendarDays}
                                        bookingData={bookingData}
                                        hoverDate={hoverDate}
                                        isCheckIn={false}
                                        formatDate={formatDate}
                                        isDateInRange={isDateInRange}
                                        handleCheckInSelect={handleCheckInSelect}
                                        handleCheckOutSelect={handleCheckOutSelect}
                                        setHoverDate={setHoverDate}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Guests Dropdown */}
                        <div className='relative w-full h-full py-2 flex items-center justify-evenly cursor-pointer'>
                            <div className='h-full w-full flex flex-col justify-evenly'>
                                <p className='ml-[30px]'>Guests</p>
                                <p className='text-xs ml-[30px] text-gray-800'>
                                    {bookingData.guests} {bookingData.guests <= 1 ? "Guest" : "Guests"}
                                </p>
                            </div>
                            <div
                                ref={guestsDropdownRef}
                                className='relative flex items-center justify-center gap-3 h-full w-full'
                                onClick={() => setIsGuestsDropdownOpen(!isGuestsDropdownOpen)}
                            >
                                <div className='px-4 py-2 text-md items-center flex before:rounded-md outline-none'>
                                    <ChevronDown className='mt-1' size={20} /> <p>{bookingData.guests} {bookingData.guests <= 1 ? "Guest" : "Guests"}</p>
                                </div>
                                {isGuestsDropdownOpen && (
                                    <div className='absolute bg-white shadow-lg rounded-lg mt-2 w-24 z-50'>
                                        {Array.from({ length: 8 }, (_, i) => i + 1).map(i => (
                                            <div
                                                key={i}
                                                onClick={() => handleGuestsSelect(i)}
                                                className='py-2 px-4 hover:bg-gray-200 cursor-pointer'
                                            >
                                                {i}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={handleBooking}
                                className='bg-red-600 hover:bg-red-700 text-white rounded-full py-3 px-8 w-full h-full'
                            >
                                Book
                            </button>
                        </div>
                    </div>
                </div>
                {/* Desktop View() */}

                <div className='container lg:hidden mt-[40px] mx-auto w-full flex flex-col items-center justify-center'>
                    <MobileBook />
                </div>
            </div>
        </div>
    );
}

export default HomeBook;
