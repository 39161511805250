import React from 'react';
import { ArrowRight } from 'lucide-react';
import dine from "../asset/dinein.jpg"

const DineSection = () => {
    return (
        <section className="px-4">
            <div className="max-w-7xl mx-auto">
                <div className="relative rounded-xl overflow-hidden shadow-xl">
                    <a href="/dinein" className='hover:brightness-110'>
                        <img
                            src={dine}
                            alt="Delighted Cuisine Experience"
                            className="w-full h-[500px] object-cover"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/40 to-transparent flex flex-col justify-end p-8 md:p-12">
                            <h2 className="text-3xl md:text-4xl font-bold text-white mb-3">
                                Delighted Cuisine
                            </h2>
                            <p className="text-white/90 text-lg max-w-md mb-6">
                                Savor a harmony of flavors, expertly crafted with passion and the finest ingredients for an unforgettable dining experience.
                            </p>
                            <button
                                className="bg-white text-red-600 px-6 py-3 rounded-lg font-semibold flex items-center hover:bg-indigo-50 transition-colors self-start"
                            >
                                Explore Dine-In Options
                                <ArrowRight className="ml-2 w-5 h-5" />
                            </button>
                        </div>
                    </a>
                </div>
            </div>
        </section >
    );
};

export default DineSection;