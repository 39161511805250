import React, { useState, useEffect, useRef } from 'react';
import { IndianRupee, ChevronUp, ChevronDown, CalendarDays } from 'lucide-react';
import CustomDatePicker from './CustomDatePicker';

function MobileBookingBar({
    data,
    fromDate,
    toDate,
    adults,
    extrabed,
    calculateNights,
    calculateTotalPrice,
    calculateTotalPriceAfterDiscount,
    onReserve,
    setFromDate,
    setToDate,
    setAdults,
    setExtraBed,
}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const formatDate = (date) => {
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
        });
    };

    // Custom Dropdown component
    function CustomDropdown({ label, options, selectedValue, onSelect }) {
        const [isOpen, setIsOpen] = useState(false);
        const [positionUpward, setPositionUpward] = useState(false);
        const dropdownRef = useRef(null);
    
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
    
        const checkDropdownPosition = () => {
            if (dropdownRef.current) {
                const rect = dropdownRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;
    
                // Check if there's enough space below, else display upward
                if (windowHeight - rect.bottom < 300) {
                    setPositionUpward(true);
                } else {
                    setPositionUpward(false);
                }
            }
        };
    
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            window.addEventListener("resize", checkDropdownPosition);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
                window.removeEventListener("resize", checkDropdownPosition);
            };
        }, []);
    
        useEffect(() => {
            if (isOpen) checkDropdownPosition();
        }, [isOpen]);
    
        return (
            <div className="relative w-full" ref={dropdownRef}>
                <div
                    className="border-2 border-gray-400 rounded-md px-2 py-4 cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedValue || label}
                </div>
                {isOpen && (
                    <div
                        className={`absolute ${
                            positionUpward ? "bottom-full mb-1" : "top-full mt-1"
                        } left-0 w-full bg-white border-2 border-gray-400 rounded-md shadow-md z-10`}
                    >
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer rounded-md"
                                onClick={() => {
                                    onSelect(option);
                                    setIsOpen(false);
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="fixed bottom-0 left-0 w-full bg-white shadow-xl rounded-t-2xl z-40">
            <div className={`px-4  flex ${isExpanded ? "py-0 justify-center" : " py-5 justify-between"} rounded-t-2xl items-center cursor-pointer`}

                onClick={() => setIsExpanded(!isExpanded)}
            >
                {!isExpanded ? <>
                <div className={`flex items-center gap-2`}>
                    {!isExpanded && <ChevronUp />}
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex gap-2 items-center">
                            <span className="font-bold text-xl">{formatDate(fromDate)}</span>
                            <span>-</span>
                            <span className="font-bold text-xl">{formatDate(toDate)}</span>
                        </div>
                        <div className="text-sm text-gray-600">
                            {adults} adults, {extrabed} extra bed
                        </div>
                    </div>
                </div>
                <div className="flex items-center text-xl gap-3 font-bold">
                    <div className='flex items-center '>
                        <IndianRupee size={16} />
                    <span className='line-through text-sm'>{calculateTotalPrice()}</span></div> <span >{calculateTotalPriceAfterDiscount()}</span>
                </div>
                </> :<ChevronDown /> }
            </div>

            {isExpanded && (
                <div className="p-4 bg-white rounded-t-xl space-y-4">
                    {/* Dates Section */}
                    <div className="grid grid-cols-1 gap-4">
                        <div className='w-full'>
                            <label className="block w-full text-sm font-medium text-gray-700 mb-2">Check-in</label>
                            <CustomDatePicker
                                type="from"
                                onDateChange={(date) => setFromDate(date)}
                                selectedDate={fromDate}
                            />
                        </div>
                        <div>
                            <label className="block w-full text-sm font-medium text-gray-700 mb-2">Check-out</label>
                            <CustomDatePicker
                                type="to"
                                fromDate={fromDate}
                                onDateChange={(date) => setToDate(date)}
                                selectedDate={toDate}
                            />
                        </div>
                    </div>

                    {/* Adults Dropdown */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Adults</label>
                        <CustomDropdown
                            label="Select Adults"
                            options={Array.from({ length: data.maxguest }, (_, i) => i + 1)}
                            selectedValue={adults}
                            onSelect={(value) => setAdults(Number(value))}
                        />
                    </div>

                    {/* Extra Bed Dropdown */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Extra Bed</label>
                        <CustomDropdown
                            label="Select Extra Bed"
                            options={Array.from({ length: data.extbed + 1 }, (_, i) => i)}
                            selectedValue={extrabed}
                            onSelect={(value) => setExtraBed(Number(value))}
                        />
                    </div>

                    {/* Price and Booking Summary */}
                    <div className="flex justify-between items-center">
                        <div className="flex items-center text-gray-600">
                            <CalendarDays className="mr-2" />
                            {calculateNights() > 1 ? `${calculateNights()} nights` : `${calculateNights()} night`}
                        </div>
                        <div className="flex items-center font-bold text-xl">
                            <IndianRupee size={24} className="mr-1" />
                            {calculateTotalPriceAfterDiscount()}
                        </div>
                    </div>

                    {/* Reserve Button */}
                    <button
                        onClick={onReserve}
                        className="w-full bg-red-600 text-white py-3 rounded-md font-bold hover:bg-red-700"
                    >
                        Reserve
                    </button>
                </div>
            )}
        </div>
    );
}

export default MobileBookingBar;
