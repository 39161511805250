import React, { useEffect, useState } from 'react';
import { Sun, Cloud, CloudRain, ThermometerSun } from 'lucide-react';

function WeatherComparison() {
    const [aqi, setAqi] = useState({ Mukteshwar: null, Delhi: null });
    const [weatherData, setWeatherData] = useState({ mukteshwar: null, delhi: null });

    const locations = {
        mukteshwar: ["29.398189778238443", "79.67566808465519"],
        delhi: ["28.679079", "77.069710"]
    };

    useEffect(() => {
        const fetchWeather = async () => {
            try {
                const apiKey = '89e15739e97fac66b503f44fff952e63';
                const [mukteshwarResponse, delhiResponse, aqimuk, aqidel] = await Promise.all([
                    fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${locations.mukteshwar[0]}&lon=${locations.mukteshwar[1]}&appid=${apiKey}&units=metric`),
                    fetch(`https://api.openweathermap.org/data/2.5/weather?q=Delhi&appid=${apiKey}&units=metric`),
                    fetch(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${locations.mukteshwar[0]}&lon=${locations.mukteshwar[1]}&appid=${apiKey}`),
                    fetch(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${locations.delhi[0]}&lon=${locations.delhi[1]}&appid=${apiKey}`)
                ]);

                const mukteshwarData = await mukteshwarResponse.json();
                const delhiData = await delhiResponse.json();
                const aqimukData = await aqimuk.json();
                const aqidelData = await aqidel.json();

                setAqi({
                    Mukteshwar: aqimukData.list[0].components.pm10,
                    Delhi: aqidelData.list[0].components.pm10
                });

                setWeatherData({
                    mukteshwar: mukteshwarData,
                    delhi: delhiData
                });
            } catch (error) {
                console.error("Weather fetch error", error);
            }
        };
        fetchWeather();
    }, []);

    const bgvideo = {
        delhi: {
            day: "https://d2ehq5aws28ia0.cloudfront.net/daydel.mp4",
            evening: "https://d2ehq5aws28ia0.cloudfront.net/sun.mp4",
            night: "https://d2ehq5aws28ia0.cloudfront.net/nightdel.mp4",
        },
        mukteshwar: {
            day: "https://d6pltnqzug32h.cloudfront.net/weather/mukdinn.mp4",
            night: "https://d2ehq5aws28ia0.cloudfront.net/night.mp4",
        }
    };

    const displayvideo = [];
    const displayTime = () => {
        const hours = new Date().getHours();
        if (hours >= 6 && hours < 16) {
            displayvideo.push(bgvideo.delhi.day, bgvideo.mukteshwar.day);
        } else if (hours >= 18 && hours < 24) {
            displayvideo.push(bgvideo.delhi.night, bgvideo.mukteshwar.night);
        } else {
            displayvideo.push(bgvideo.delhi.evening, bgvideo.mukteshwar.night);
        }
    };
    displayTime();

    const getWeatherIcon = (weatherCondition) => {
        if (!weatherCondition) return <ThermometerSun className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 text-white" />;
        switch (weatherCondition.main) {
            case 'Haze': return <Sun className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 text-white" />;
            case 'Clouds': return <Cloud className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 text-white" />;
            case 'Rain': return <CloudRain className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 text-white" />;
            default: return <ThermometerSun className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 text-white" />;
        }
    };

    const renderWeatherCard = (location, weatherInfo) => (
        <div className="w-full max-w-md md:max-w-xl rounded-2xl relative aspect-video overflow-hidden shadow-2xl mb-4">
            <video
                className="absolute h-full w-full object-cover z-0"
                autoPlay
                muted
                loop
                playsInline
            >
                <source src={location === 'Delhi' ? displayvideo[0] : displayvideo[1]} type="video/mp4" />
            </video>

            <div className="absolute w-full h-full bg-black/30 inset-0 z-10"></div>
            <div className="absolute z-20 px-4 sm:px-6 md:px-8 flex flex-col justify-around w-full h-full text-white">
                <p className="text-2xl sm:text-3xl md:text-5xl font-bold text-end tracking-wide">{location}</p>
                <div className="text-center flex items-end justify-between w-full">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-6xl sm:text-7xl md:text-[100px] font-bold leading-none">
                            {weatherInfo ? Math.floor(weatherInfo.main.temp) : ""}°
                        </p>
                        <span className="text-sm sm:text-base md:text-xl">Celsius</span>
                    </div>
                    <div>
                        <div className="flex flex-col items-end">
                            {getWeatherIcon(weatherInfo?.weather[0])}
                            {weatherInfo && (
                                <p className="mt-1 sm:mt-2 text-xs sm:text-sm capitalize">{weatherInfo.weather[0].description}</p>
                            )}
                        </div>
                        <div className="mt-2 sm:mt-4 flex justify-end space-x-2 sm:space-x-4">
                            <div className="text-end">
                                <p className="text-xs sm:text-sm">Humidity</p>
                                <p className="font-bold text-sm sm:text-base">{weatherInfo?.main.humidity}%</p>
                            </div>
                            <div className="text-end">
                                <p className="text-xs sm:text-sm">Wind</p>
                                <p className="font-bold text-sm sm:text-base">{Math.round(weatherInfo?.wind.speed)} km/h</p>
                            </div>
                            <div className="text-end">
                                <p className="text-xs sm:text-sm">AQI</p>
                                <p className="font-bold text-sm sm:text-base">{Math.floor(aqi[location])}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="w-full">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl pattaya sm:text-4xl md:text-5xl text-red-600 font-bold py-6 sm:py-8 md:py-10 text-center md:text-center">
                    Weather Difference
                </h1>
                <div className="flex flex-col md:flex-row gap-4 sm:gap-6 md:gap-8 justify-center items-center ">
                    {renderWeatherCard('Mukteshwar', weatherData.mukteshwar)}
                    {renderWeatherCard('Delhi', weatherData.delhi)}
                </div>
            </div>
        </div>
    );
}

export default WeatherComparison;