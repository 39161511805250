import React, { useState, useEffect, useRef } from 'react';
import ImageCarousel from './ImageCarousel';
import CustomDatePicker from './CustomDatePicker';
import { IndianRupee, UsersRound, CalendarDays, User, X } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cottageData } from './cottageData';
import Amenities from '../../screens/Amenities';
import BookingConfirmation from './BookingConfirmation';
import MobileBookingBar from './MobileBookingBar';
import { Helmet } from 'react-helmet';


function CustomDropdown({ label, options, selectedValue, onSelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const [positionUpward, setPositionUpward] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const checkDropdownPosition = () => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Check if there's enough space below, else display upward
            if (windowHeight - rect.bottom < 150) {
                setPositionUpward(true);
            } else {
                setPositionUpward(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("resize", checkDropdownPosition);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("resize", checkDropdownPosition);
        };
    }, []);

    useEffect(() => {
        if (isOpen) checkDropdownPosition();
    }, [isOpen]);

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div
                className="border-2 border-gray-400 rounded-md px-2 py-4 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedValue || label}
            </div>
            {isOpen && (
                <div
                    className={`absolute ${positionUpward ? "bottom-full mb-1" : "top-full mt-1"
                        } left-0 w-full bg-white border-2 border-gray-400 rounded-md shadow-md z-10`}
                >
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer rounded-md"
                            onClick={() => {
                                onSelect(option);
                                setIsOpen(false);
                            }}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

function RenderCottage() {
    const { title } = useParams();
    const data = cottageData[title];
    const location = useLocation();
    const availa = location.state;
    const navigate = useNavigate()

    useEffect(() => {
        if (!data) {
            navigate('/notfound'); // Redirect to NotFound page
        }
    }, [data, navigate]);

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [adults, setAdults] = useState(1);
    const [extrabed, setExtraBed] = useState(0);
    const [fromDate, setFromDate] = useState(availa?.checkIn ? new Date(availa.checkIn) : today);
    const [toDate, setToDate] = useState(availa?.checkOut ? new Date(availa.checkOut) : tomorrow);
    const [formData, setFormData] = useState(null);
    const [Book, setBook] = useState(false);

    useEffect(() => {
        if (availa) {
            const ext = availa.guests ? availa.guests - data.maxguest : 0;
            const guest = ext > 0 ? availa.guests - ext : availa.guests;
            setAdults(guest != null ? guest : 1);
            setExtraBed(ext >= 0 ? ext : 0);
        }
    }, [availa, data]);

    // Disable body scrolling when contact popup is open
    useEffect(() => {
        document.body.style.overflow = Book ? 'hidden' : 'auto';
        return () => (document.body.style.overflow = 'auto');
    }, [Book]);

    const calculateNights = () => {
        if (fromDate && toDate) {
            const diffTime = Math.abs(toDate - fromDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }
        return 0;
    };

    const calculateTotalPrice = () => {
        return (calculateNights() * data.price);
    };
    const calculateTotalPriceAfterDiscount = () => {
        const totalPrice = calculateTotalPrice(); // Call the function
        return totalPrice - (totalPrice * 15) / 100 + extrabed * 500 * calculateNights(); // Apply 15% discount
    };

    function bookNow() {
        const newFormData = {
            title: data.title,
            adults: adults,
            extrabed: extrabed,
            fromDate: fromDate,
            toDate: toDate,
            totalPrice: calculateTotalPrice(),
            finalPrice: calculateTotalPriceAfterDiscount()
        };

        setFormData(newFormData);
        setBook(true);
    }

    return (
        data && <>
            <div>
                {/* Helmet for SEO */}
                <Helmet>
                    <title>{data.meta}</title>
                    <meta
                        name="description"
                        content={data.description}
                    />
                </Helmet>
                <div className="w-full h-[100px]"></div>
                <div className="container mx-auto p-5">
                    <div className="flex xl:flex-row flex-col gap-5">
                        <ImageCarousel images={data.images} alt={data.alt} />
                        <div className="mt-[5px] hidden lg:block h-full w-full">
                            <p className="text-4xl font-bold dmserif text-gray-800">{data.title}</p>
                            <p className="text-xl font-semibold text-gray-500 mt-[10px]">{data.plot}</p>

                            {/* Date and Guest Selection */}
                            <div className="flex h-full w-full gap-3 mt-[20px]">
                                <div>
                                    <p className="text-lg text-gray-500 font-semibold">From</p>
                                    <CustomDatePicker
                                        type="from"
                                        onDateChange={(date) => setFromDate(date)}
                                        selectedDate={fromDate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="text-lg text-gray-500 w-full font-semibold ml-[20px]">Adults</p>
                                    <CustomDropdown
                                        label="Select Adults"
                                        options={Array.from({ length: data.maxguest }, (_, i) => i + 1)}
                                        selectedValue={adults}
                                        onSelect={setAdults}
                                    />
                                </div>
                            </div>

                            {/* Date and Extra Bed Selection */}
                            <div className="flex h-full w-full gap-3 mt-[20px]">
                                <div>
                                    <p className="text-lg text-gray-500 font-semibold">To</p>
                                    <CustomDatePicker
                                        type="to"
                                        fromDate={fromDate}
                                        onDateChange={(date) => setToDate(date)}
                                        selectedDate={toDate}
                                    />
                                </div>
                                <div className="w-full">
                                    <p className="text-lg text-gray-500 w-full font-semibold ml-[20px]">Extra Bed</p>
                                    <CustomDropdown
                                        label="0"
                                        options={Array.from({ length: data.extbed + 1 }, (_, i) => i)}
                                        selectedValue={extrabed}
                                        onSelect={(value) => setExtraBed(Number(value))}
                                    />
                                </div>
                            </div>

                            {/* Price and Reserve Button */}
                            <div className="mt-[30px] h-full w-full flex justify-between">

                                <div>

                                    <div className="flex gap-5">
                                        <p className="flex gap-2 text-gray-600 items-center text-2xl"><UsersRound /> {adults}</p>
                                        <p className="flex gap-2 text-gray-600 items-center text-2xl"><User /> {extrabed}</p>
                                    </div>

                                    <p className="flex gap-2 text-gray-600 items-center mt-[10px] text-xl">
                                        <CalendarDays />
                                        {calculateNights() > 1 ? `${calculateNights()} nights` : `${calculateNights()} night`}
                                    </p>

                                    <div className="flex items-center mt-[10px] text-2xl font-bold text-gray-600 mb-2">
                                        <IndianRupee size={24} className="mr-1" />
                                        <span className='line-through opacity-80'>{calculateTotalPrice()}</span><span className='ml-3'>{calculateTotalPriceAfterDiscount()}</span>
                                    </div>
                                    
                                </div>

                                <div className="flex flex-col justify-end">
                                    <div className="flex items-center text-2xl font-bold text-gray-800 mb-2">
                                        <IndianRupee size={24} className="mr-1" />
                                        {data.price}
                                        <span className="text-sm text-gray-500 ml-1">/ night</span>
                                    </div>
                                    <button onClick={bookNow} className="bg-red-600 text-white py-[10px] px-[20px] rounded-md font-bold hover:bg-red-700">Reserve</button>
                                </div>

                            </div>
                        </div>

                        <div className='lg:hidden block'>
                            {/* Render MobileBookingBar */}
                            <MobileBookingBar
                                data={data}
                                fromDate={fromDate}
                                toDate={toDate}
                                adults={adults}
                                extrabed={extrabed}
                                calculateNights={calculateNights}
                                calculateTotalPrice={calculateTotalPrice}
                                calculateTotalPriceAfterDiscount={calculateTotalPriceAfterDiscount}
                                onReserve={bookNow}
                                setFromDate={setFromDate}
                                setToDate={setToDate}
                                setAdults={setAdults}
                                setExtraBed={setExtraBed}
                            />
                        </div>
                    </div>

                    <section className="lg:ml-[15px]">
                        <p className="text-4xl font-bold mt-[20px] dmserif text-start mb-3 text-gray-800">{data.title}</p>
                        <p className="text-justify caudex">
                            {data.summary}
                        </p>
                    </section>

                    <div className="ml-[15px]">
                        <h2 className="text-3xl mt-[40px] font-bold text-start mb-8 pattaya text-red-600">Amenities</h2>
                        <Amenities />
                    </div>

                    <section className="rounded-lg overflow-hidden mt-2">
                        <div className="p-4">
                            <h3 className="text-base font-semibold text-gray-800">Homestay Policies</h3>
                        </div>
                        <div className="px-4 text-sm text-gray-700 space-y-1">
                            <ul className="space-y-2 caudex">
                                {[
                                    "Please present your ID card, Passport, or Temporary Residence Card upon Check-in.",
                                    "Check-in time is 1PM. Early check-in is subject to availability and additional charges may apply.",
                                    "Check-out time is 12PM. Extensions depend on availability and may occur extra charges.",
                                    "Lock doors when leaving or sleeping. Safety lockers are available. Management is not liable for lost valuables.",
                                    "Pets are allowed. Inform us before your reservation.",
                                    "Guests are responsible for damages caused to hotel property by themselves or their visitors.",
                                    "Carrying explosives, weapons, or dangerous chemicals into the hotel is strictly prohibited.",
                                    "We are couple-friendly. Valid ID proof is required for all guests."
                                ].map((text, index) => (
                                    <li key={index} className="flex items-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500 mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                                        </svg>
                                        <span>{text}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </section>
                </div>
            </div>

            <div className='flex justify-center my-12'>
                <button onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }} className="bg-red-600 text-white md:block hidden w-[400px] py-[10px] px-[20px] rounded-md hover:bg-red-700">Reserve Now</button>
            </div >

            {Book && formData && (
                <div className="fixed top-0 mx-auto z-50 left-0 bg-gray-800/95 h-[100vh] w-full md:p-8">
                    <div className='w-full h-full absolute inset-0 ' onClick={() => setBook(false)} />
                    <button
                        onClick={() => setBook(false)}
                        className="fixed z-40 top-6 right-6 text-gray-700 bg-gray-300 hover:bg-gray-400 p-3 rounded-full shadow-lg"
                    >
                        <X size={24} />
                    </button>
                    <div className='max-w-7xl p-8 mx-auto flex flex-col items-center justify-center h-full w-full'>
                        <BookingConfirmation data={formData} />
                    </div>
                </div>
            )
            }
        </>
    );
}

export default RenderCottage;
