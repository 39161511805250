import React from 'react';
import { ArrowRight } from 'lucide-react';

const DiscountSection = () => {
    return (
        <section className="px-4 mt-16">
            <div className="max-w-7xl mx-auto">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                    <a href="/availability" className='hover:brightness-110 block'>
                        <img
                            src="https://d2ehq5aws28ia0.cloudfront.net/drone/4.jpg"
                            alt="Delighted Cuisine Experience"
                            className="w-full h-[350px] object-cover object-center"
                        />
                        <div className="absolute h-full w-full bg-black/30 top-0 left-0 flex flex-col justify-center items-center text-center">
                            <div className='flex flex-col items-center justify-center'>
                                <div className="text-[50px] md:text-[100px] pattaya text-white">
                                    15% off
                                </div>
                                <p className="text-white/90 px-12 text-sm md:text-md max-w-md">
                                    Experience luxury redefined—elegant stays, stunning views, and unmatched comfort await you.
                                </p>
                                <button className="bg-white text-red-600 mt-5 px-8 py-3 rounded-lg font-semibold flex items-center hover:bg-indigo-50 transition-colors">
                                    Book Now
                                    <ArrowRight className="ml-2 w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default DiscountSection;
