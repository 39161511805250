import React from 'react';
import MenuRes from '../components/MenuRes';
import { Helmet } from 'react-helmet';

function Dining() {
  return (
    <div className="w-full">
      {/* Helmet for SEO */}
      <Helmet>
        <title>Restaurant in Mukteshwar | Dine-In at Indus Valley Homestay</title>
        <meta
          name="description"
          content="Looking for a hotel in Mukteshwar with the best cafe, restaurant or eatery in town? Indus Valley Homestay serves a delightful range of meals in a cool location"
        />
      </Helmet>
      {/* Header Section */}
      <div className='w-full h-[40vh] md:h-[450px] flex flex-col' style={{
        backgroundImage: `url("https://d6pltnqzug32h.cloudfront.net/dineinheader.jpeg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom 20% center',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className='h-full w-full items-center justify-center flex flex-col'>
          <div className='w-full h-full flex flex-col items-center justify-center'>
            <div className='text-center text-2xl mt-[70px] text-white'>
              <p className='md:text-4xl text-4xl lg:text-8xl text-white pattaya'>Hillcrest Cafè</p>
              <p className='text-base md:text-xl text-white font-medium mt-[20px] lg:text-4xl'>Enjoy Your Food With Breathtaking View</p>
            </div>
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      <div className="container mx-auto mt-[80px] flex flex-col gap-5 px-5">
        <div className="flex flex-col md:flex-row justify-center items-center gap-5">
          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <img
              src="https://d6pltnqzug32h.cloudfront.net/welcome.png"
              className="object-contain h-[400px] md:h-[600px] w-full"
              alt="restaurant in mukteswar"
            />
          </div>

          {/* Text Section */}
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-start justify-start">
            <div className='w-[80%]'>
              {/* <p className="text-2xl pattaya text-red-600">Hillcrest Cafe</p> */}
              <h1 className="text-3xl md:text-5xl font-bold pattaya text-red-600 my-[10px] text-center md:text-start">Welcome to Hillcrest Cafè</h1>
              <p className="text-justify text-md md:text-lg caudex">
                Welcome to <b>Hillcrest Cafe</b>, a charming dine-in spot at the heart of <b>Indus Valley</b>. Designed as an exclusive haven for township residents and their guests, we also warmly welcome visitors staying at <b>Indus Valley Homestay</b>. We also provide in-room dine in service to all the guests. During the cold winter nights, you can order your food in your food at your villa with just one phone call.
                <br /><br /> At <b>Hillcrest Cafe</b>, we believe that delicious food alone is not enough; the place where you have your meal should be an experience. That’s why our restaurant features large windows offering breathtaking Himalayan views, making every bite even more special.

              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-12 md:mt-0 flex flex-col gap-5 px-5">
        <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-5">
          {/* Text Section */}
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-end">
            <div className='w-[80%]'>
              <p className="text-3xl md:text-5xl pattaya text-red-600 text-center md:text-start">A Taste of Togetherness</p>
              <br />
              <ul class="space-y-4 caudex">
                <li class="text-md md:text-lg text-gray-800 text-justify">
                  <span class="font-bold">Delicious Cuisines:</span> From hearty Himalayan specialties to international favorites, every dish is a treat for your taste buds.
                </li>
                <li class="text-md md:text-lg text-gray-800 text-justify">
                  <span class="font-bold">Tranquil Ambience:</span> Whether it’s a leisurely breakfast with the sunrise or a quiet dinner beneath the stars, every seat offers a stunning panoramic view.
                </li>
                <li class="text-md md:text-lg text-gray-800 text-justify">
                  <span class="font-bold">Exclusivity:</span> Reserved solely for township residents and their guests, Hillcrest Cafe is designed to foster community, connection, and comfort.
                </li>
              </ul>
              <br />
              <p class="text-xl caudex font-bold text-red-600 ">
                <i>Come and experience the perfect blend of delicious food, warm hospitality, and serene views—only at Hillcrest Cafe.</i>
              </p>

            </div>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <img
              src="https://d6pltnqzug32h.cloudfront.net/dinein2.png"
              className="object-contain h-[400px] md:h-[600px] w-full"
              alt="hotel in mukteshwar"
            />
          </div>
        </div>
      </div>

      {/* Food Image */}
      <div className="h-[400px] md:h-[600px] w-full flex mt-[40px] flex-col items-center justify-center">
        <img className='h-full w-full object-cover object-bottom' src="https://d6pltnqzug32h.cloudfront.net/food.jpg" alt="best food in mukteswar" />
      </div>

      {/* Menu Section */}
      <div className="w-full mt-10 mb-[40px] px-5">
      <h2 className="text-4xl md:text-6xl text-center pattaya text-red-600">Our Menu</h2>
        <MenuRes />
      </div>

    </div>
  );
}

export default Dining;
