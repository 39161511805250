import React from 'react';
import Header from '../components/Header';
import WeatherDiff from '../components/WeatherDiff';
import CardFeed from '../components/cottagecards/CardFeed';
import Slider from "../components/carousel/Slider"
import { Helmet } from 'react-helmet';
import DineSection from '../components/DineSection';
import DiscountSection from '../components/DiscountSection';


function Home() {


  const amenities = [
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/1.png",
      title: "Secure Gated Community",
      description: "Enjoy peace of mind in our gated community.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/6.png",
      title: "Exclusive Clubhouse",
      description: "Relax and rejuvenate in our premium clubhouse.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/9.png",
      title: "Restaurant",
      description: "Savor delightful meals in our on-site restaurant.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/12.png",
      title: "Room Service",
      description: "Room service for your convenience.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/5.png",
      title: "Complimentary Parking",
      description: "Free and secure parking for all guests.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/3.png",
      title: "Breakfast",
      description: "Start your day with a delicious breakfast.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/7.png",
      title: "Landscaped Gardens",
      description: "Relax amidst our beautifully landscaped gardens.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/2.png",
      title: "Wi-Fi",
      description: "Stay connected with complimentary high-speed Wi-Fi.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/10.png",
      title: "24*7 Security Surveillance",
      description: "Advanced surveillance for your safety.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/11.png",
      title: "Panoramic Himalayan Views",
      description: "Witness breathtaking views of the Himalayas.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/8.png",
      title: "Spacious Family Rooms",
      description: "Comfortable rooms designed for families.",
    },
    {
      image: "https://d6pltnqzug32h.cloudfront.net/amenities/amenities/13.png",
      title: "Couple Friendly Rooms",
      description: "Cozy rooms for a romantic stay.",
    },
  ];

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };

  return (
    <>
      <div>
        {/* Helmet for SEO */}
        <Helmet>
          <title>Indus Valley Homestay | Mukteshwar's Best Stays</title>
          <meta
            name="description"
            content="Indus Valley Homestay offers cozy, personalized accomodations in Mukteshwar. It is indeed the best homestay in Mukteshwar. Book your stay today."
          />

          <script type="application/ld+json">
            {`
              "@context": "https://schema.org",
              "@type": "Hotel",
              "name": "Indus Valley Homestay",
              "image": "https://www.indusvalleyhomestay.com/static/media/ivh3.ceb473e9633b9054c61a.png",
              "@id": "",
              "url": "https://www.indusvalleyhomestay.com",
              "telephone": "+91-97-1125-8989",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Managher, Near Dhanachuli Bend, Sunderkhal, Nainital",
                "addressLocality": "Mukteshwar",
                "postalCode": "201304",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 29.3963976,
                "longitude": 79.6628408
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "10:00",
                "closes": "10:00"
              },
              "sameAs": [
                "https://facebook.com/IndusValleyHomestay",
                "https://instagram.com/indusvalleyhomestay"
              ]
            `}
          </script>

        </Helmet>
        <Header />
        <DiscountSection />
      </div>
      <WeatherDiff />
      <CardFeed />
      <DineSection />

      <section className="mt-12 mb-[50px]">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-8 pattaya text-red-600">Our Amenities</h2>
          <div className="grid grid-cols-3 md:grid-cols-4 gap-8 mt-[50px]">
            {amenities.map((amenity, index) => (
              <div
                key={index}
                className="flex flex-col hover:scale-110 duration-500 cursor-pointer justify-center items-center text-center"
              >
                <img
                  src={amenity.image}
                  alt={amenity.title}
                  className="mb-4 w-12 h-12 object-cover"
                />
                <h3 className="text-sm md:text-lg font-semibold mb-2">{toTitleCase(amenity.title)}</h3>
                <p className="text-gray-600 hidden md:block text-xs">{amenity.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Slider />

      <div className='w-full flex justify-center items-center absolute mt-[-55px] z-20'>
        <p className='caudex text-lg md:text-2xl font-extrabold text-red-700'>A Private Slice of Himalayan Heaven</p>
      </div>
    </>
  );
}

export default Home;
