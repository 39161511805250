import React from 'react';
import HomeBook from './HomeBook'


function Header() {
    return (
        <>
            <div className='w-full h-[70vh] md:h-[90vh] flex flex-col bgimg'>
                <HomeBook />
            </div>
        </>
    )
}

export default Header