import React from 'react';
import Cardcarousel from './Cardcarousel';
import { IndianRupee } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function CottageCard({ data, avail }) {
  const navigate = useNavigate();

  function rendercottage(e) {
    navigate(`/cottages/${e}`, { state: avail });
  }

  return (
    <div>
      <div className='w-[250px] md:w-[300px] bg-white rounded-2xl overflow-hidden transition-all duration-300 cursor-pointer'>
        <div className='h-[150px] md:h-[200px] w-full relative'>
          <Cardcarousel data={data} />
          <div className='absolute top-4 right-4 text-red-500 bg-white/95 font-bold px-3 py-1 rounded-full text-sm'>
            15% off
          </div>
        </div>

        <div onClick={() => rendercottage(data.title)}>
          <div className='px-6 sm:px-4 pt-3'>
            <p className='text-gray-600 mb-2 md:mb-4 line-clamp-2'>
              <span className='text-xl md:text-2xl text-gray-800 font-bold'>{data.name}</span><br />
              <span className='text-sm md:text-md text-gray-400 font-bold'>{data.plot}</span>
            </p>

            <div className='flex justify-between items-center mb-4 md:mb-6 '>
              <button className='px-2 md:px-4 py-1 md:py-2 text-sm md:text-md bg-gradient-to-r font-bold from-red-500 to-red-600 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1'>
                Book Now
              </button>
              <div className='flex items-center text-md md:text-2xl font-bold text-gray-800'>
                <p className='font-bold'><IndianRupee className="w-3 h-3 md:w-5 md:h-5 font-bold" /></p>
                {data.price}
                <span className='text-[10px] md:text-[12px] text-gray-500 ml-1'>/ night</span>
              </div>
            </div>

            <div className='border-t py-4 '>
              <div className='flex justify-between items-center'>
                {data.amenities.map((amenity, index) => (
                  <div
                    key={index}
                    className='flex text-sm md:text-md flex-col items-center text-gray-600 cursor-pointer hover:text-red-500 transition-colors'
                  >
                    <amenity.icon />
                    <span className=' text-[10px] md:text-xs mt-1'>{amenity.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default CottageCard;
