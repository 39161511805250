import React, { useState, useCallback, useEffect } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';

const Gallery = ({ images }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // Minimum swipe distance in pixels to trigger navigation
  const minSwipeDistance = 50;

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (isLightboxOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    // Cleanup function to ensure scroll is restored when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLightboxOpen]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      nextImage();
    } else if (isRightSwipe) {
      prevImage();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isLightboxOpen) {
        switch (e.key) {
          case 'Escape':
            closeLightbox();
            break;
          case 'ArrowRight':
            nextImage();
            break;
          case 'ArrowLeft':
            prevImage();
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isLightboxOpen, lightboxIndex]);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => setIsLightboxOpen(false);

  const nextImage = useCallback(() => {
    setLightboxIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  const prevImage = useCallback(() => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images]);

  return (
    <>
      {/* Image Thumbnails */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {images.map((image, index) => (
          <div
            key={index}
            className="aspect-video overflow-hidden rounded-lg shadow-lg cursor-pointer group"
            onClick={() => openLightbox(index)}
          >
            <img
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
            />
          </div>
        ))}
      </div>

      {/* Lightbox */}
      <AnimatePresence>
        {isLightboxOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            className="fixed inset-0 z-50 bg-gradient-to-br from-white via-gray-200 to-gray-300 flex items-center justify-center p-4"
          >
            {/* Close Button */}
            <button
              onClick={closeLightbox}
              className="absolute top-6 right-6 text-gray-700 bg-gray-300 hover:bg-gray-400 p-3 rounded-full shadow-lg"
            >
              <X size={24} />
            </button>

            {/* Image Display */}
            <div className="relative w-full max-w-5xl max-h-[80vh]">
              <motion.img
                key={lightboxIndex}
                src={images[lightboxIndex]}
                alt={`Lightbox Image ${lightboxIndex + 1}`}
                className="w-full h-full object-contain rounded-xl shadow-lg"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              />

              {/* Navigation Buttons */}
              <button
                onClick={prevImage}
                className="absolute left-20 w-16 h-16 md:left-5 md:top-1/2 bottom-[-150px] transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-4 rounded-full shadow-lg"
              >
                <ChevronLeft size={32} />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-20 w-16 h-16 md:right-5 md:top-1/2 bottom-[-150px] transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-gray-700 p-4 rounded-full shadow-lg"
              >
                <ChevronRight size={32} />
              </button>
            </div>

            {/* Image Counter */}
            <div className="absolute bottom-8 bg-gray-400/60 text-gray-700 text-sm px-4 py-2 rounded-lg shadow">
              {lightboxIndex + 1} / {images.length}
            </div>

            {/* Decorative Elements */}
            <div className="absolute inset-0 pointer-events-none">
              <div className="w-full h-full bg-gradient-to-t from-gray-300 via-transparent to-gray-300 opacity-10" />
              <div className="absolute top-10 left-10 w-20 h-20 bg-gradient-to-br from-blue-400 to-green-300 rounded-full blur-xl opacity-30 animate-pulse" />
              <div className="absolute bottom-10 right-10 w-24 h-24 bg-gradient-to-br from-pink-400 to-orange-300 rounded-full blur-xl opacity-30 animate-pulse" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Gallery;
