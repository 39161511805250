import React, { useState, useRef } from 'react';
import CottageCard from './CottageCard';
import { cottagecarddata } from './cottagecarddata';

function CardFeed({ avail }) {
  const data = cottagecarddata;
  const [studioActiveIndex, setStudioActiveIndex] = useState(0);
  const [cottageActiveIndex, setCottageActiveIndex] = useState(0);
  const [apartmentActiveIndex, setApartmentActiveIndex] = useState(0);
  
  // Create refs for each scrollable container
  const studioScrollRef = useRef(null);
  const apartmentScrollRef = useRef(null);
  const cottageScrollRef = useRef(null);
  
  // Touch event states
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // Filter function based on availability (maxGuest >= avail.guests)
  const filterByGuests = (cottageList) => {
    if (avail && avail.guests) {
      return cottageList.filter(
        (cottage) => cottage.maxGuest + cottage.extbed >= avail.guests
      );
    }
    return cottageList; // If avail is not provided, return all cottages
  };

  // Filter studios and cottages based on avail.guests if avail exists
  const filteredStudios = filterByGuests(data.studios);
  const filteredCottages = filterByGuests(data.cottages);
  const filteredAppartment = filterByGuests(data.appartments);

  // Handle scroll events to update active index
  const handleScroll = (event, type) => {
    const scrollPosition = event.target.scrollLeft;
    const cardWidth = event.target.children[0].offsetWidth + 48; // width + gap
    const newIndex = Math.round(scrollPosition / cardWidth);
    
    if (type === 'studio') {
      setStudioActiveIndex(newIndex);
    } else if (type === 'apartment') {
      setApartmentActiveIndex(newIndex);
    } else {
      setCottageActiveIndex(newIndex);
    }
  };

  // Handle touch events for swiping
  const handleTouchStart = (e, type) => {
    setTouchStart(e.targetTouches[0].clientX);
    setTouchEnd(null);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (type) => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    
    let scrollContainer;
    let currentIndex;
    let setIndex;
    let items;
    
    if (type === 'studio') {
      scrollContainer = studioScrollRef.current;
      currentIndex = studioActiveIndex;
      setIndex = setStudioActiveIndex;
      items = filteredStudios;
    } else if (type === 'apartment') {
      scrollContainer = apartmentScrollRef.current;
      currentIndex = apartmentActiveIndex;
      setIndex = setApartmentActiveIndex;
      items = filteredAppartment;
    } else {
      scrollContainer = cottageScrollRef.current;
      currentIndex = cottageActiveIndex;
      setIndex = setCottageActiveIndex;
      items = filteredCottages;
    }
    
    if (!scrollContainer) return;
    
    if (isLeftSwipe && currentIndex < items.length - 1) {
      // Next card
      const nextIndex = currentIndex + 1;
      setIndex(nextIndex);
      scrollToCard(scrollContainer, nextIndex);
    }
    
    if (isRightSwipe && currentIndex > 0) {
      // Previous card
      const prevIndex = currentIndex - 1;
      setIndex(prevIndex);
      scrollToCard(scrollContainer, prevIndex);
    }
  };
  
  // Helper function to scroll to specific card
  const scrollToCard = (container, index) => {
    if (!container) return;
    const cardWidth = container.children[0].offsetWidth + 48; // width + gap
    container.scrollTo({
      left: cardWidth * index,
      behavior: 'smooth'
    });
  };
  
  // Helper to navigate to specific card via dots
  const navigateToCard = (index, type) => {
    if (type === 'studio') {
      setStudioActiveIndex(index);
      scrollToCard(studioScrollRef.current, index);
    } else if (type === 'apartment') {
      setApartmentActiveIndex(index);
      scrollToCard(apartmentScrollRef.current, index);
    } else {
      setCottageActiveIndex(index);
      scrollToCard(cottageScrollRef.current, index);
    }
  };

  return (
    <div className='mx-auto container w-full md:px-8'>
      {filteredStudios.length > 0 && (
        <div className='max-w-7xl mb-12 mx-auto w-[90%] md:w-full'>
          <div className='pt-[40px] pattaya text-4xl md:text-5xl text-red-600 text-center md:text-center'>
            Studios
          </div>
          <div 
            ref={studioScrollRef}
            className='ml-[20px] md:ml-0 flex mt-[40px] overflow-hidden flex-nowrap items-center md:justify-center gap-12 scroll-smooth'
            onScroll={(e) => handleScroll(e, 'studio')}
            onTouchStart={(e) => handleTouchStart(e, 'studio')}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => handleTouchEnd('studio')}
          >
            {filteredStudios.map((cottage, i) => (
              <CottageCard key={i} data={cottage} avail={avail}/>
            ))}
          </div>
          {/* Dots indicator for mobile */}
          <div className='flex justify-center gap-2 mt-4 md:hidden'>
            {filteredStudios.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full transition-all duration-300 ${
                  index === studioActiveIndex ? 'bg-red-600 w-4' : 'bg-gray-300'
                }`}
                onClick={() => navigateToCard(index, 'studio')}
              />
            ))}
          </div>
        </div>
      )}

      {filteredAppartment.length > 0 && (
        <div className='max-w-7xl mb-12 mx-auto w-[90%] md:w-full'>
          <div className='pt-[40px] pattaya text-4xl md:text-5xl text-red-600 text-center md:text-center'>
            Apartments
          </div>
          <div 
            ref={apartmentScrollRef}
            className='ml-[20px] md:ml-0 flex mt-[40px] overflow-hidden md:flex-wrap items-center md:justify-center gap-12 scroll-smooth'
            onScroll={(e) => handleScroll(e, 'apartment')}
            onTouchStart={(e) => handleTouchStart(e, 'apartment')}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => handleTouchEnd('apartment')}
          >
            {filteredAppartment.map((cottage, i) => (
              <CottageCard key={i} data={cottage} avail={avail}/>
            ))}
          </div>
          {/* Dots indicator for mobile */}
          <div className='flex justify-center gap-2 mt-4 md:hidden'>
            {filteredAppartment.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full transition-all duration-300 ${
                  index === apartmentActiveIndex ? 'bg-red-600 w-4' : 'bg-gray-300'
                }`}
                onClick={() => navigateToCard(index, 'apartment')}
              />
            ))}
          </div>
        </div>
      )}

      {filteredCottages.length > 0 && (
        <div className='container mb-12 mx-auto w-[90%] md:w-full'>
          <div className='pt-[40px] pattaya text-4xl md:text-5xl text-red-600 text-center md:text-center'>
            Independent Villas
          </div>
          <div 
            ref={cottageScrollRef}
            className='ml-[20px] md:ml-0 flex mt-[40px] overflow-hidden flex-nowrap items-center md:justify-center gap-12 scroll-smooth'
            onScroll={(e) => handleScroll(e, 'cottage')}
            onTouchStart={(e) => handleTouchStart(e, 'cottage')}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => handleTouchEnd('cottage')}
          >
            {filteredCottages.map((cottage, i) => (
              <CottageCard key={i} data={cottage} avail={avail}/>
            ))}
          </div>
          {/* Dots indicator for mobile */}
          <div className='flex justify-center gap-2 mt-4 md:hidden'>
            {filteredCottages.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full transition-all duration-300 ${
                  index === cottageActiveIndex ? 'bg-red-600 w-4' : 'bg-gray-300'
                }`}
                onClick={() => navigateToCard(index, 'cottage')}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CardFeed;