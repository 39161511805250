import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Phone, Check } from 'lucide-react';

function ContactUs() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    name: '',
    phone: ''
  });

  const [error, setError] = useState(null); // Track errors

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://account.solidperformers.com/leadsapi/data/44a0f8006194b6294f37366bf5ffa11b', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contactDetails)
      });

      if (!response.ok) {
        throw new Error('Failed to submit contact details. Please try again.');
      }

      // Handle successful submission
      setIsSubmitted(true);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
    }
  };

  return (
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="relative z-10 w-full max-w-2xl bg-white rounded-3xl shadow-2xl overflow-hidden p-8"
      >
        {!isSubmitted ? (
          <>
            <h3 className="text-2xl font-bold text-red-800 mb-6 text-center">
              Contact Us
            </h3>

            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Name Input */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="text-red-500" size={20} />
                </div>
                <input
                  type="text"
                  name="name"
                  required
                  value={contactDetails.name}
                  onChange={handleInputChange}
                  placeholder="Full Name"
                  className="w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300"
                />
              </div>

              {/* Phone Input */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone className="text-red-500" size={20} />
                </div>
                <input
                  type="tel"
                  name="phone"
                  required
                  value={contactDetails.phone}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                  className="w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300"
                />
              </div>

              {/* Error Message */}
              {error && (
                <p className="text-red-500 text-center">{error}</p>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-red-600 text-white py-3 rounded-lg hover:bg-red-700 transition-colors duration-300 flex items-center justify-center space-x-2 shadow-md hover:shadow-lg mt-4"
              >
                <Check size={20} />
                <span>Submit</span>
              </button>
            </form>
          </>
        ) : (
          <div className="text-center">
            <Check size={64} className="mx-auto mb-4 text-green-500" />
            <h2 className="text-3xl font-bold text-red-800 mb-4">Message Sent!</h2>
            <p className="text-gray-600 mb-6">
              Thank you for reaching out! Our team will get back to you shortly.
            </p>
          </div>
        )}
      </motion.div>
  );
}

export default ContactUs;
